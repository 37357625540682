import React from "react";
import "./con_2.css";
import { Link } from "react-router-dom";

const Index = () => {
  const Step1 = (
    <svg
      width="64"
      height="63"
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="31.5" r="31.5" fill="#02366a" />
      <g clip-path="url(#clip0_3033_10502)">
        <path
          d="M46.6291 39.4848L42.1634 35.0191C40.5686 33.4242 37.8572 34.0622 37.2193 36.1355C36.7408 37.571 35.1459 38.3684 33.7105 38.0494C30.5207 37.2519 26.2145 33.1052 25.4171 29.7559C24.9386 28.3205 25.8956 26.7256 27.331 26.2472C29.4043 25.6092 30.0423 22.8979 28.4474 21.303L23.9817 16.8373C22.7058 15.7209 20.7919 15.7209 19.6755 16.8373L16.6452 19.8676C13.6149 23.0574 16.9642 31.5103 24.4601 39.0063C31.9561 46.5023 40.4091 50.0111 43.5988 46.8213L46.6291 43.791C47.7456 42.5151 47.7456 40.6012 46.6291 39.4848Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3033_10502">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(15.5 16)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  const Step2 = (
    <svg
      width="64"
      height="63"
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="31.5" r="31.5" fill="#02366a" />
      <path
        d="M31.5001 16C25.1098 16 19.9109 21.1989 19.9109 27.5891C19.9109 35.5196 30.2821 47.1621 30.7236 47.6538C31.1384 48.1158 31.8625 48.1149 32.2765 47.6538C32.7181 47.1621 43.0893 35.5196 43.0893 27.5891C43.0891 21.1989 37.8903 16 31.5001 16ZM31.5001 33.4199C28.285 33.4199 25.6693 30.8043 25.6693 27.5891C25.6693 24.374 28.285 21.7584 31.5001 21.7584C34.7151 21.7584 37.3308 24.3741 37.3308 27.5892C37.3308 30.8043 34.7151 33.4199 31.5001 33.4199Z"
        fill="white"
      />
    </svg>
  );
  const Step3 = (
    <svg
      width="64"
      height="63"
      viewBox="0 0 64 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="31.5" r="31.5" fill="#02366a" />
      <g clip-path="url(#clip0_4866_6479)">
        <path
          d="M34.1743 35.6056C33.3782 36.1363 32.4534 36.4169 31.5 36.4169C30.5466 36.4169 29.6219 36.1363 28.8258 35.6056L15.7131 26.8635C15.6403 26.815 15.5694 26.7645 15.5 26.7125V41.0373C15.5 42.6797 16.8328 43.9831 18.4458 43.9831H44.5541C46.1965 43.9831 47.4999 42.6503 47.4999 41.0373V26.7124C47.4304 26.7645 47.3594 26.8152 47.2864 26.8638L34.1743 35.6056Z"
          fill="white"
        />
        <path
          d="M16.7531 25.3036L29.8658 34.0457C30.3622 34.3767 30.9311 34.5421 31.4999 34.5421C32.0689 34.5421 32.6378 34.3766 33.1342 34.0457L46.2469 25.3036C47.0316 24.7808 47.5 23.9058 47.5 22.9614C47.5 21.3376 46.1789 20.0166 44.5552 20.0166H18.4448C16.8211 20.0167 15.5 21.3377 15.5 22.963C15.5 23.9058 15.9685 24.7808 16.7531 25.3036Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_4866_6479">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(15.5 16)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  const Sec4data = [
    {
      id: 1,
      icon: Step1,
      btnnames: "PHONE",
      title: "CALL US ON ",
      discription: "+91 9978 66 1212",
      link: "tel:+91 9978 66 1212",
    },
    {
      id: 2,
      icon: Step2,
      btnnames: "LOCATION",
      title: "Located At",
      discription:
        "B/1020, Sun Westbank, Opp City Gold, Riverfront,Ashram Road, Ahmedabad, Gujarat, India 380009.",
    },
    {
      id: 3,
      icon: Step3,
      btnnames: "E-MAIL",
      title: "MAIL US ON",
      discription: "wealthlyte@outlook.com",
      link: "mailto:wealthlyte@outlook.com",
    },
  ];

  return (
    <div className="Sec2_main_div">
      <div className="Sec2_sub_main_div">
        <div className="sec2_1_div">
          <button className="btn_of_sec2">How Can We Help You</button>
        </div>
        <div className="sec2_2_div">
          <h2>
            Let us know your requirement to get a competitive quote for your
            needs
          </h2>
        </div>
        <div className="sec2_3_div">
          {Sec4data.map((item, index) => {
            return (
              <div className="card_sec4_main">
                <div className="card_4_1">
                  <Link className="btn_of_sec2" to={item.link}>
                    {item.btnnames}
                  </Link>
                </div>
                <div className="card_4_2">
                  <span>{item.icon}</span>
                </div>
                <div className="card_4_3">
                  <h3>{item.title}</h3>
                  <p>{item.discription}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Index;
