import React from "react";
import "./S_sec3.css";
import sec3Img from "../../../assets/Service_section2/service_sec_3.png";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  const tick = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="20"
      viewBox="0 0 26 20"
      fill="none">
      <path d="M2 9.5L9.5 17L24.5 2" stroke="#02366a" stroke-width="4"></path>
      <defs>
        <linearGradient
          x1="1.19209e-07"
          y1="17"
          x2="28"
          y2="0.999999"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.924092" stop-color="#02366a"></stop>
          <stop offset="0.924092" stop-color="#02366a"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
  return (
    <div className="main_Service_sec3">
      <div className="sub_main_sec3">
        <div className="_sec3_container1">
          <h1>
            An Integrated Approach to Fulfilling Your Accounting Outsourcing
            Requirements
          </h1>
          <p>
            Our dedicated team of specialists excels in delivering diverse
            outsourcing accounting services tailored to your needs. These
            encompass industry-specific bookkeeping, payroll, tax preparation,
            and more, designed to enhance the growth and prosperity of your CPA
            & accounting firm.
          </p>
          <button className="btn2" onClick={() => navigate("/ContactUs")}>
            Get Started
          </button>
        </div>
        <div className="_sec3_container2">
          <div className="img_div_of_sec3">
            <img src={sec3Img} alt="image" />
          </div>
        </div>
        <div className="_sec3_container3">
          <div className="left_side_text_container">
            <span>{tick}</span>
            <div className="text_box_sec3">
              <h2> Financial Analytics </h2>
              <p>
                In today's rapidly changing world and fast moving industrial
                competition a business has to think from the angle of 360
                degree.
              </p>
            </div>
          </div>
          <div className="left_side_text_container">
            <span>{tick}</span>
            <div className="text_box_sec3">
              <h2> Cashflow Analysis </h2>
              <p>
                {" "}
                Cash flow analysis is defined as analysing a business income
                inflows and outflows during a certain period.{" "}
              </p>
            </div>
          </div>
          <div className="left_side_text_container">
            <span>{tick}</span>
            <div className="text_box_sec3">
              <h2> CPA Business Growth </h2>
              <p>
                {" "}
                Wealthlyte work with diverse growth strategies for CPA firms,
                let's connect and discuss how we can contribute to your firm
                growth.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
