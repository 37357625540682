import React from "react";
import "./Services.css";
import S_sec1 from "./S_sec1/index";
import S_sec2 from "./S_sec2/index";
import S_sec3 from "./S_sec3/index";

const index = () => {
  return (
    <div>
      <S_sec1 />
      <S_sec2 />
      <S_sec3 />
    </div>
  );
};

export default index;
