import React from "react";
import "./Acm2.css";
import img_service1 from "../../../assets/Srvice_page1/Sec_11.png";

const Index = () => {
  const Tick = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85 12.15L6.75 14.25L13.5 21L28.5 6L26.4 3.9L13.5 16.8L8.85 12.15ZM27 15C27 21.6 21.6 27 15 27C8.4 27 3 21.6 3 15C3 8.4 8.4 3 15 3C16.2 3 17.25 3.15 18.3 3.45L20.7001 1.05C18.9 0.45 16.95 0 15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15H27Z"
        fill="#07417bd1"
      />
    </svg>
  );
  return (
    <div className="main_service_sec_2">
      <div className="submain_service_sec2">
        <div className="image_div_for_sec2">
          <div className="_inner_img_div_for_sec2">
            <img src={img_service1} alt="" />
          </div>
        </div>

        <div className="text_contentc_for_sec2">
          <div className="inner_text_div_of_sec2">
            <h2>CLIENT SERVICE EXECUTIVE</h2>
            <p>
              The support staff assist you in client support services by
              coordination between the client and firm. They can help you with
              payment recovery calls and follow-ups, attending the requirements
              of clients and raising appropriate action request from the firm.
            </p>
            <ul>
              <li>
                Cultivating solid relationships with clients through the
                provision of exceptional after-sales service.
              </li>
              <li>
                Creating a positive onboarding experience for new clients.
              </li>
              <li>
                Regularly interacting with clients through telephone calls,
                email communications, or face-to-face meetings.
              </li>
              <li>
                Identifying ways to overcome clients’ initial dislike of company
                products.
              </li>
              <li>
                Maintaining an accurate record of all existing and potential
                clients.
              </li>
              <li>
                Responding to client inquiries in a timely and professional
                manner.
              </li>
              <li>
                Training and providing overall guidance to client service
                employees.
              </li>
              <li>
                Organizing training seminars to enable clients to fully utilize
                product features and benefits.
              </li>
              <li>
                Ensuring that the correct terms of sale are entered into the
                client database to prevent avoidable billing issues.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="sub_bottom_text">
        <div className="last_text">
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Input customer and account data from source documents</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Scan and store documents on designated platforms</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Review data for deficiencies or errors</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Rectification of errors and incompatibilities</h3>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Index;
