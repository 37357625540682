import React, { useState } from "react";
import "./con_3.css";
import sec10 from "../../../assets/Contactform_img.png";

import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { ContactusSchemas } from "../../../schemas/index";
import Backdrop from "@mui/material/Backdrop";

import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";

const initialValues = {
  Name: "",
  Email: "",
  Subject: "",
  Message: "",
};

const Index = () => {
  const [loading, setloading] = useState(false);
  const onSubmit = async (values, { resetForm }) => {
    setloading(true);
    try {
      const response = await emailjs.send(
        "service_ref8awn",
        "template_jnalvz5",
        {
          from_name: values.Name,
          Subject: values.Subject,
          user_name: values.Name,
          user_Email: values.Email,
          user_message: values.Message,
        },
        {
          publicKey: "lAP06q8vBrXoSJBXE",
        }
      );
      setloading(false);
      resetForm();
      console.log("🚀 ~ onSubmit ~ response:", response);
      console.log("SUCCESS!");
    } catch (err) {
      setloading(false);
      if (err instanceof EmailJSResponseStatus) {
        console.log("EMAILJS FAILED...", err);
        return;
      }

      console.log("ERROR", err);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ContactusSchemas,
    onSubmit,
    // onSubmit: (values, { resetForm }) => {
    //   console.log(values);

    //   resetForm();
    // },
  });
  return (
    <>
      <div className="Sec10_main_div">
        <div className="Sec10_sub_main_div">
          <div className="heding_div_of_section10">
            <div className="btn_of_sec2">Contact us</div>
            <h1>Get in Touch!</h1>
            <p>We are here for you! How can we help?</p>
          </div>
          <div className="sub_inner_div_of_sec10">
            <div className="left_side_main_div">
              <div className="main_form_div">
                <form onSubmit={handleSubmit}>
                  <div className="main_div_of_Contactus_input">
                    <div className="contactus_input_div">
                      <input
                        type="text"
                        name="Name"
                        id="Name"
                        value={values.Name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Full Name"
                      />
                    </div>
                    {errors.Name && touched.Name ? (
                      <p className="errors_msg_p">{errors.Name} </p>
                    ) : null}
                  </div>
                  <div className="main_div_of_Contactus_input">
                    <div className="contactus_input_div">
                      <input
                        type="email"
                        name="Email"
                        id="Email"
                        value={values.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Email"
                      />
                    </div>
                    {errors.Email && touched.Email ? (
                      <p className="errors_msg_p">{errors.Email} </p>
                    ) : null}
                  </div>
                  <div className="main_div_of_Contactus_input">
                    <div className="contactus_input_div">
                      <input
                        type="text"
                        name="Subject"
                        id="Subject"
                        value={values.Subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Subject "
                      />
                    </div>
                    {errors.Subject && touched.Subject ? (
                      <p className="errors_msg_p">{errors.Subject} </p>
                    ) : null}
                  </div>
                  <div className="main_div_of_Contactus_text">
                    <div className="contactus_textarea_div">
                      <textarea
                        name="Message"
                        id="Message"
                        cols="30"
                        rows="10"
                        value={values.Message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Message"></textarea>
                    </div>
                    {errors.Message && touched.Message ? (
                      <p className="errors_msg_p">{errors.Message} </p>
                    ) : null}
                  </div>
                  <button className="form_btn" type="submit">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
            <div className="Right_side_main_div">
              <div className="imagediv_of_sec10">
                <img src={sec10} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#1249328c",
            }}
            open={true}>
            <span class="loader"></span>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Index;
