import React from "react";
import "./Acm2.css";
import img_service1 from "../../../assets/Srvice_page1/TaxPrepare.png";

const Index = () => {
  const Tick = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85 12.15L6.75 14.25L13.5 21L28.5 6L26.4 3.9L13.5 16.8L8.85 12.15ZM27 15C27 21.6 21.6 27 15 27C8.4 27 3 21.6 3 15C3 8.4 8.4 3 15 3C16.2 3 17.25 3.15 18.3 3.45L20.7001 1.05C18.9 0.45 16.95 0 15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15H27Z"
        fill="#07417bd1"
      />
    </svg>
  );
  return (
    <div className="main_service_sec_2">
      <div className="submain_service_sec2">
        <div className="image_div_for_sec2">
          <div className="_inner_img_div_for_sec2">
            <img src={img_service1} alt="" />
          </div>
        </div>

        <div className="text_contentc_for_sec2">
          <div className="inner_text_div_of_sec2">
            <h2>Tax Preparer</h2>
            <p>
              Tax Preparers are well versed with country-specific return filings
              for Corporates, Individuals and partnership. Tax preparers help
              you reduce the work load for your accounting firm at the time of
              tax season.
            </p>
            <ul>
              <li>Calculate tax payments</li>
              <li>
                Estimate tax returns, deductibles, liabilities, and expenses
              </li>
              <li>Prepare quarterly and annual reports</li>
              <li>Collect data from income statements and expense documents</li>
              <li>Complete clients’ tax documentation</li>
              <li>Review data on reports and detect possible errors</li>
              <li>Answer tax- and finance-related questions</li>
              <li>Research new tax trends and regulations</li>
              <li>Advise individuals and companies on tax strategies</li>
              <li>Ensure compliance with government tax rules</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sub_bottom_text">
        <div className="last_text">
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>C-Corp</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>S-Corp</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Income Taxes</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Partnership Income Taxes</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Fiduciary Income Taxes</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
