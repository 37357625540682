import React from "react";
import "./Acm2.css";
import img_service1 from "../../../assets/Srvice_page1/ar_ap.png";

const Index = () => {
  const Tick = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85 12.15L6.75 14.25L13.5 21L28.5 6L26.4 3.9L13.5 16.8L8.85 12.15ZM27 15C27 21.6 21.6 27 15 27C8.4 27 3 21.6 3 15C3 8.4 8.4 3 15 3C16.2 3 17.25 3.15 18.3 3.45L20.7001 1.05C18.9 0.45 16.95 0 15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15H27Z"
        fill="#07417bd1"
      />
    </svg>
  );
  return (
    <div className="main_service_sec_2">
      <div className="submain_service_sec2">
        <div className="image_div_for_sec2">
          <div className="_inner_img_div_for_sec2">
            <img src={img_service1} alt="" />
          </div>
        </div>

        <div className="text_contentc_for_sec2">
          <div className="inner_text_div_of_sec2">
            <h2>AR/AP ASSISTANT</h2>
            <p>
              AR/AP Assistant processes the invoices after verification of the
              transaction and party’s ledger. He regularly updates and maintain
              the ledger of parties for quick processing after reconciliation of
              statements.
            </p>
            <ul>
              <li>
                Prepares work to be accomplished by gathering and sorting
                documents and related information.
              </li>
              <li>
                Pays invoices by verifying transaction information, scheduling,
                preparing disbursements, and obtaining authorization of payment.
              </li>
              <li>
                Obtains revenue by verifying transaction information, computing
                charges, and refunds, and preparing and mailing invoices.
              </li>
              <li>
                Collects revenue by reminding delinquent accounts and notifying
                customers of insufficient payments.
              </li>
              <li>
                Prepares financial reports by collecting, analyzing, and
                summarizing account information and trends.
              </li>
              <li>
                Maintains accounting ledgers by posting account transactions.
              </li>
              <li>
                Verifies accounts by reconciling statements and transactions.
              </li>
              <li>
                Resolves account discrepancies by investigating documentation,
                issuing stop payments and adjustments.
              </li>
              <li>
                Maintains financial security by following internal accounting
                controls.
              </li>
              <li>
                Secures financial information by completing database backups.
              </li>
              <li>
                Maintains financial historical records by filing accounting
                documents.
              </li>
              <li>
                Contributes to team effort by accomplishing related results as
                needed.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="sub_bottom_text">
        <div className="last_text">
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Input customer and account data from source documents</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Scan and store documents on designated platforms</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Review data for deficiencies or errors</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Rectification of errors and incompatibilities</h3>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Index;
