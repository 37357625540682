import React from "react";
import "./Acm2.css";
import img_service1 from "../../../assets/Srvice_page1/SA_1.png";

const Index = () => {
  const Tick = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85 12.15L6.75 14.25L13.5 21L28.5 6L26.4 3.9L13.5 16.8L8.85 12.15ZM27 15C27 21.6 21.6 27 15 27C8.4 27 3 21.6 3 15C3 8.4 8.4 3 15 3C16.2 3 17.25 3.15 18.3 3.45L20.7001 1.05C18.9 0.45 16.95 0 15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15H27Z"
        fill="#07417bd1"
      />
    </svg>
  );
  return (
    <div className="main_service_sec_2">
      <div className="submain_service_sec2">
        <div className="image_div_for_sec2">
          <div className="_inner_img_div_for_sec2">
            <img src={img_service1} alt="" />
          </div>
        </div>

        <div className="text_contentc_for_sec2">
          <div className="inner_text_div_of_sec2">
            <h2>STAFF ACCOUNTANT</h2>
            <p>
              The Staff Accountant assists the Accounting Manager in preparation
              of ledgers, income statements and other financial documentations.
              We deploy the professionals with attention on details for accurate
              recording.
            </p>
            <ul>
              <li>
                Performs general cost accounting and other related duties for
                the organization.
              </li>
              <li>
                Prepares monthly balance sheets, income statements, and profit
                and loss statements.
              </li>
              <li>Maintains the general ledger.</li>
              <li>
                Codes invoices, sets up new accounts, reconciles accounts, and
                closes the monthly books.
              </li>
              <li>
                Reconciles bank accounts at least monthly, verifies deposits,
                and addresses inquiries from banks.
              </li>
              <li>
                Reconciles cash disbursement accounts, payroll, customer
                accounts, and other financial accounts; manages accounts
                receivable collections.
              </li>
              <li>
                Verifies and/or completes payment of invoices associated with
                accounts payable and ensures payments are charged to the
                appropriate accounts.
              </li>
              <li>
                Provides outside auditors with assistance; gathers necessary
                account information and documents to perform annual audit.
              </li>
              <li>
                iles tax forms with federal, state, and local government
                agencies.
              </li>
              <li>
                Coordinates with software vendor to maintain accounting software
                systems; recommends updates to enhance the accounting software.
              </li>
              <li>Manages the purchasing and invoicing system.</li>
              <li>
                <li class="cs64D34F3">
                  <span class="cs1B16EEB5">
                    Maintains knowledge of acceptable accounting practices and
                    procedures.
                  </span>
                </li>
              </li>
              <li>Performs other related duties as assigned.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sub_bottom_text">
        <div className="last_text">
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Prepare financial statements</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Prepare & file tax returns</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Monitor accounts receivable and accounts payable</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Monthly bank settlements</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Detailed reconciliations of balance sheet</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Documentation for external auditors</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
