import { Route, Routes } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Mainpage from "./pages/ManiPage/index.jsx";
import Aboutus from "./pages/AboutUs/index.jsx";
import Blog from "./pages/Blog/index.jsx";
import Services from "./pages/Services/index.jsx";
import Contactus from "./pages/Contactus/index.jsx";
import Navbar from "./components/UI/Navbar/index.jsx";
import Footer from "./components/UI/Footer/index.jsx";
import ScrollToTop from "./components/ScrollTop.jsx";
import Accountingmanager from "./pages/Services_1/index.jsx";
import StaffAccountant from "./pages/Services_2/index.jsx";
import Bookkeeper from "./pages/Services_3/index.jsx";
import Taxpreparer from "./pages/Services_4/index.jsx";
import TaxManager from "./pages/Services_5/index.jsx";
import AuditAssociates from "./pages/Services_6/index.jsx";
import DataEntrySpecialist from "./pages/Services_7/index.jsx";
import Billingexecutive from "./pages/Services_8/index.jsx";
import ArApAssistant from "./pages/Services_9/index.jsx";
import AdminSupportStaff from "./pages/Services_10/index.jsx";
import ClientServiceExecutive from "./pages/Services_11/index.jsx";
import BusinessDevelopmentAssociate from "./pages/Services_12/index.jsx";
import VirtualExecutiveAssistant from "./pages/Services_13/index.jsx";
import Whatsapp from "./Comman/WhatsApp/index.jsx";

import BlogDetails from "./pages/BlogDetails/index.jsx";
function App() {
  return (
    <div className="App">
      <Navbar />
      <ScrollToTop />
      <Whatsapp />
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/AboutUs" element={<Aboutus />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Blogdetails" element={<BlogDetails />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/ContactUs" element={<Contactus />} />
        <Route path="/AccountingManager" element={<Accountingmanager />} />
        <Route path="/StaffAccountant" element={<StaffAccountant />} />
        <Route path="/Bookkeeper" element={<Bookkeeper />} />
        <Route path="/TaxPreparer" element={<Taxpreparer />} />
        <Route path="/TaxManager" element={<TaxManager />} />
        <Route path="/AuditAssociates" element={<AuditAssociates />} />
        <Route path="/DataEntrySpecialist" element={<DataEntrySpecialist />} />
        <Route path="/Billingexecutive" element={<Billingexecutive />} />
        <Route path="/ArApAssistant" element={<ArApAssistant />} />
        <Route path="/AdminSupportStaff" element={<AdminSupportStaff />} />
        <Route
          path="/ClientServiceExecutive"
          element={<ClientServiceExecutive />}
        />
        <Route
          path="/BusinessDevelopmentAssociate"
          element={<BusinessDevelopmentAssociate />}
        />
        <Route
          path="/VirtualExecutiveAssistant"
          element={<VirtualExecutiveAssistant />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
