import React from "react";
import Navbar from "../../components/UI/Navbar/index";
import Footer from "../../components/UI/Footer/index";
import Section1 from "../../pages/ManiPage/Sections/Section1";
import Section2 from "../../pages/ManiPage/Sections/Section2";
import Section3 from "../../pages/ManiPage/Sections/Section3";
import Section4 from "../../pages/ManiPage/Sections/Section4";
import Section5 from "../../pages/ManiPage/Sections/Section5";
import Section6 from "../../pages/ManiPage/Sections/Section6";
import Section7 from "../../pages/ManiPage/Sections/Section7";
// import Section8 from "../../pages/ManiPage/Sections/Section8";
// import Section9 from "../../pages/ManiPage/Sections/Section9";

const Index = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      {/* <Navbar /> */}
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      {/* <Section7 /> */}
      {/* <Section8 />
      <Section9 /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Index;
