import React from "react";
import "./Acm2.css";
import img_service1 from "../../../assets/Srvice_page1/ACM_1.png";

const Index = () => {
  const Tick = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85 12.15L6.75 14.25L13.5 21L28.5 6L26.4 3.9L13.5 16.8L8.85 12.15ZM27 15C27 21.6 21.6 27 15 27C8.4 27 3 21.6 3 15C3 8.4 8.4 3 15 3C16.2 3 17.25 3.15 18.3 3.45L20.7001 1.05C18.9 0.45 16.95 0 15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15H27Z"
        fill="#07417bd1"
      />
    </svg>
  );
  return (
    <div className="main_service_sec_2">
      <div className="submain_service_sec2">
        <div className="image_div_for_sec2">
          <div className="_inner_img_div_for_sec2">
            <img src={img_service1} alt="" />
          </div>
        </div>

        <div className="text_contentc_for_sec2">
          <div className="inner_text_div_of_sec2">
            <h2>ACCOUNTING MANAGER</h2>
            <p>
              The Accounting Managers hold experience in management of accounts,
              tax and financial aspects of business. You can reduce your work
              burden by delegating the crucial and time-consuming task of
              supervising the performance of the firm.
            </p>
            <ul>
              <li>
                Establishes internal controls and guidelines for accounting
                transactions and budget preparation.
              </li>
              <li>
                Oversees preparation of business activity reports, financial
                forecasts, and annual budgets.
              </li>
              <li>
                Oversees the production of periodic financial reports; ensures
                that the reported results comply with generally accepted
                accounting principles or financial reporting standards
              </li>
              <li>
                Responsible for tax planning throughout the fiscal year; files
                annual corporate tax return
              </li>
              <li>
                Audits accounts to ensure compliance with state and federal
                regulations; coordinates with outside auditors and provides
                needed information for the annual external audit.
              </li>
              <li>
                Presents recommendations to management on short- and long-term
                financial objectives and policies
              </li>
              <li>
                Provides financial analysis with an emphasis on capital
                investments, pricing decisions, and contract negotiations.
              </li>
              <li>
                Ensures compliance with local, state, and federal government
                requirements.
              </li>
              <li>Performs other related duties as necessary or assigned.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sub_bottom_text">
        <div className="last_text">
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Implementation of latest tools for smooth functions</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>
              Periodical and precise reporting to management for sound decisions
            </h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Supervising overall performance of entity</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Periodical reconciliation of data</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
