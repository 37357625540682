import React from "react";
import "./Acm2.css";
import img_service1 from "../../../assets/Srvice_page1/sec_12.png";

const Index = () => {
  const Tick = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85 12.15L6.75 14.25L13.5 21L28.5 6L26.4 3.9L13.5 16.8L8.85 12.15ZM27 15C27 21.6 21.6 27 15 27C8.4 27 3 21.6 3 15C3 8.4 8.4 3 15 3C16.2 3 17.25 3.15 18.3 3.45L20.7001 1.05C18.9 0.45 16.95 0 15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15H27Z"
        fill="#07417bd1"
      />
    </svg>
  );
  return (
    <div className="main_service_sec_2">
      <div className="submain_service_sec2">
        <div className="image_div_for_sec2">
          <div className="_inner_img_div_for_sec2">
            <img src={img_service1} alt="" />
          </div>
        </div>

        <div className="text_contentc_for_sec2">
          <div className="inner_text_div_of_sec2">
            <h2>BUSINESS DEVELOPMENT ASSOCIATE</h2>
            <p>
              The Business Development Associates conducts market research to
              surge the sales. This support staff develops new client
              acquisition strategy by identifying new business opportunities.
            </p>
            <ul>
              <li>
                Willing to familiarize yourself with the company’s vision and
                mission, seeking to accomplish set goals and objectives.
              </li>
              <li>
                Conducting market research and identifying potential clients.
              </li>
              <li>
                Cultivating strong relationships with new clients, while
                maintaining existing client relationships.
              </li>
              <li>
                Collating and maintaining client information in the CRM
                database.
              </li>
              <li>
                Working closely with staff across departments to implement
                growth strategies.
              </li>
              <li>
                Developing and implementing sales strategies, client service and
                retention plans, and analyzing sales data to inform or update
                marketing strategies.
              </li>
              <li>
                Assist with drafting business plans, sales pitches,
                presentations, reference material, and other documents as
                required.
              </li>
              <li>
                Ability to manage multiple projects concurrently and meet
                deadlines.
              </li>
              <li>Identify new business opportunities and partners.</li>
              <li>
                Demonstrate strong interpersonal skills with the ability to
                engage effectively with various levels of management, staff, and
                clients.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="sub_bottom_text">
        <div className="last_text">
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Input customer and account data from source documents</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Scan and store documents on designated platforms</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Review data for deficiencies or errors</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Rectification of errors and incompatibilities</h3>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Index;
