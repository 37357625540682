import React from "react";
import "./BlogDetails.css";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();
  console.log("🚀 ~ Index ~ location:", location);
  const Blogitem = location.state;
  return (
    <div className="main_ditails_of_blog">
      <div className="sub_main_div_of_blog">
        <div className="main_heding_div">
          <div className="backbtn_div" onClick={() => navigate(-1)}>
            <span>&larr;</span>
          </div>
          <h1>{Blogitem?.title}</h1>
        </div>
        <div className="main_image_div">
          <div className="image_box_blog">
            <img src={Blogitem?.img} alt="" />
          </div>
        </div>

        <div className="main_text_div">
          <p style={{ whiteSpace: "pre-wrap" }}>{Blogitem?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Index;
