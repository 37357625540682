import React from "react";
import "./Blog1.css";

const Index = () => {
  return (
    <div className="main_blog_sec1">
      <h1> Stay Tuned to Latest Accounting News & Updates </h1>
      <p> IRS & Accouting News </p>
    </div>
  );
};

export default Index;
