// import { useState, useEffect, useRef } from "react";
// import "./nav.css";
// import logo from "../../../assets/Logo.png";
// import logo2 from "../../../assets/Logo.png";
// import { NavLink, useLocation, useNavigate } from "react-router-dom";
// import { Fade as Hamburger } from "hamburger-react";
// // import { Link } from "react-scroll";
// import { Link } from "react-router-dom";
// import Dropdown from "react-bootstrap/Dropdown";

// const Index = () => {
//   const [screenSize, setScreenSize] = useState(getCurrentDimension());
//   const [lang, setlang] = useState();

//   function getCurrentDimension() {
//     return {
//       width: window.innerWidth,
//       height: window.innerHeight,
//     };
//   }
//   useEffect(() => {
//     const updateDimension = () => {
//       setScreenSize(getCurrentDimension());
//     };
//     window.addEventListener("resize", updateDimension);

//     return () => {
//       window.removeEventListener("resize", updateDimension);
//     };
//   }, [screenSize]);
//   const Navigate = useNavigate();

//   let menuref = useRef();

//   const [open, setIsopen] = useState(false);
//   const [onshow, setonshow] = useState(false);

//   useEffect(() => {
//     let handler = (event) => {
//       if (menuref.current) {
//         if (!menuref.current.contains(event.target)) {
//           setIsopen(false);
//           setonshow(false);
//           // setshow(false);
//         }
//       }
//     };
//     document.addEventListener("mousedown", handler);
//     document.addEventListener("scroll", handler);

//     return () => {
//       document.removeEventListener("mousedown", handler);
//       document.removeEventListener("scroll", handler);
//     };
//   }, []);
//   const toggle = (item) => {
//     setIsopen(false);
//   };
//   const handlehide = () => {
//     setIsopen(!open);
//   };
//   const pathname = useLocation();
//   useEffect(() => {
//     setlang(pathname.pathname);
//   }, [pathname]);

//   const Langchange = () => {
//     Navigate("/Services");
//   };

//   return (
//     <div ref={menuref} className="Main_navbar">
//       <div className="mainnav">
//         <nav id="navbar">
//           <Link to="/" className="logo">
//             <img src={logo} alt="" style={{ width: "100%" }} />
//           </Link>
//           <Link to="/" className="logo2">
//             <img src={logo2} alt="" style={{ width: "70%" }} />
//           </Link>
//           <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
//             <li>
//               <Link to="/" onClick={() => toggle("")}>
//                 Home
//               </Link>
//             </li>
//             <li>
//               <Link to="/Aboutus" offset={-100} onClick={() => toggle("")}>
//                 About Us
//               </Link>
//             </li>
//             <li>
//               <Link to="/Services" offset={-100} onClick={() => toggle("")}>
//                 Services
//               </Link>
//             </li>
//             {/* ? service drop down */}
//             {/* <Dropdown>
//               <Dropdown.Toggle id="dropdown-basic" onChange={Langchange}>
//                 Services
//               </Dropdown.Toggle>

//               <Dropdown.Menu>
//                 <Dropdown.Item>
//                   <li>
//                     <Link
//                       to="/Services"
//                       className="Service_link"
//                       onClick={() => toggle("")}>
//                       Bookkeeping
//                     </Link>
//                   </li>
//                 </Dropdown.Item>
//                 <Dropdown.Item>
//                   <li>
//                     <Link
//                       to="/Services"
//                       className="Service_link"
//                       onClick={() => toggle("")}>
//                       Tax Preparation
//                     </Link>
//                   </li>
//                 </Dropdown.Item>
//                 <Dropdown.Item>
//                   <li>
//                     <Link
//                       to="/Services"
//                       className="Service_link"
//                       onClick={() => toggle("")}>
//                       Finance & Accounting
//                     </Link>
//                   </li>
//                 </Dropdown.Item>
//                 <Dropdown.Item>
//                   <li>
//                     <Link
//                       to="/Services"
//                       className="Service_link"
//                       onClick={() => toggle("")}>
//                       Payroll Processing
//                     </Link>
//                   </li>
//                 </Dropdown.Item>
//                 <Dropdown.Item>
//                   <li>
//                     <Link
//                       to="/Services"
//                       className="Service_link"
//                       onClick={() => toggle("")}>
//                       Remote Staffing
//                     </Link>
//                   </li>
//                 </Dropdown.Item>
//               </Dropdown.Menu>
//             </Dropdown> */}
//             {/* ? service drop down */}
//             <li>
//               <Link to="/Blog" offset={-100} onClick={() => toggle("")}>
//                 Blog
//               </Link>
//             </li>

//             <li>
//               <Link to="/ContactUs" offset={-100} onClick={() => toggle("")}>
//                 Contact us
//               </Link>
//             </li>

//             {/* <li>
//               <Link to="NewSection" offset={-100} onClick={() => toggle("")}>
//                 Hire
//               </Link>
//             </li> */}
//             <button className="loginbtn" onClick={() => Navigate("/ContactUs")}>
//               Get a Quote
//             </button>
//           </ul>

//           <div className="menubtn">
//             <Hamburger onToggle={handlehide} toggled={open} />
//           </div>
//         </nav>
//       </div>
//     </div>
//   );
// };

// export default Index;
import React, { useState, useEffect, useRef } from "react";
import "./nav.css";
import logo from "../../../assets/Logo.png";
import logo2 from "../../../assets/Logo2.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
// import { Link } from "react-scroll";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

const Index = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [lang, setlang] = useState();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const Navigate = useNavigate();

  let menuref = useRef();

  const [open, setIsopen] = useState(false);
  const [onshow, setonshow] = useState(false);

  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
          setonshow(false);
          // setshow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);
  const toggle = (item) => {
    setIsopen(false);
  };
  const handlehide = () => {
    setIsopen(!open);
  };
  const pathname = useLocation();
  useEffect(() => {
    setlang(pathname.pathname);
  }, [pathname]);

  const Langchange = () => {
    Navigate("/Services");
  };

  return (
    <div ref={menuref} className="Main_navbar">
      <div className="mainnav">
        <nav id="navbar">
          <Link to="/" className="logo">
            <img src={logo} alt="" />
          </Link>
          <Link to="/" className="logo2">
            <img src={logo2} alt="" />
          </Link>
          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            <li className={pathname.pathname === "/" ? "active" : ""}>
              <Link to="/" onClick={() => toggle("")}>
                Home
              </Link>
            </li>
            <li className={pathname.pathname === "/Aboutus" ? "active" : ""}>
              <Link to="/Aboutus" offset={-100} onClick={() => toggle("")}>
                About Us
              </Link>
            </li>
            <li className={pathname.pathname === "/Services" ? "active" : ""}>
              <Link to="/Services" offset={-100} onClick={() => toggle("")}>
                Services
              </Link>
            </li>
            <li className={pathname.pathname === "/Blog" ? "active" : ""}>
              <Link to="/Blog" offset={-100} onClick={() => toggle("")}>
                Blog
              </Link>
            </li>
            <li className={pathname.pathname === "/ContactUs" ? "active" : ""}>
              <Link to="/ContactUs" offset={-100} onClick={() => toggle("")}>
                Contact us
              </Link>
            </li>
            <button className="loginbtn" onClick={() => Navigate("/ContactUs")}>
              Get a Quote
            </button>
          </ul>

          <div className="menubtn">
            <Hamburger onToggle={handlehide} toggled={open} />
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Index;
