import { useEffect, useState } from "react";
import "./Sec6.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useFormik } from "formik";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import Backdrop from "@mui/material/Backdrop";

const Index = () => {
  const [loading, setloading] = useState(false);
  const initialValues = {
    Name: "",
    Email: "",
    Message: "",
  };
  const playIcon = (
    <svg
      width="70"
      height="70"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="50" fill="#D9D9D9" />
      <path
        d="M65.0807 49.9998L42.651 67.4113L42.5108 32.7705L65.0807 49.9998Z"
        fill="#02366a"
      />
    </svg>
  );
  useEffect(() => {
    AOS.init();
  }, []);
  const onSubmit = async (values, { resetForm }) => {
    try {
      setloading(true);
      const response = await emailjs.send(
        "service_ref8awn",
        "template_jnalvz5",
        {
          from_name: values.Name,
          user_name: values.Name,
          user_Email: values.Email,
          user_message: values.Message,
        },
        {
          publicKey: "lAP06q8vBrXoSJBXE",
        }
      );
      setloading(false);
      resetForm();
      console.log("🚀 ~ onSubmit ~ response:", response);
      console.log("SUCCESS!");
    } catch (err) {
      setloading(false);
      if (err instanceof EmailJSResponseStatus) {
        console.log("EMAILJS FAILED...", err);
        return;
      }

      console.log("ERROR", err);
    }
  };
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
    // onSubmit: (values, { resetForm }) => {
    //   console.log(values);

    //   resetForm();
    // },
  });

  return (
    <>
      <div className="Sec6_main_div">
        <div className="Sec6_sub_div">
          <div className="text_of_sec6">
            <span className="play_svg" data-aos="zoom-in">
              {playIcon}
            </span>

            <div className="contact_info_div_sec6" data-aos="zoom-in">
              <h3>
                How does outsourced accounting serve as the driving force behind
                the growth of CPA firms?
              </h3>
              <div className="contact_div_of_sec6">
                <h6> Call For More Informaion</h6>
                <a href="tel:+91 9978 66 1212">+91 9978 66 1212</a>
              </div>
            </div>
          </div>
          <div className="sec6_form_div">
            <form
              onSubmit={handleSubmit}
              action=""
              className="from_main_div"
              data-aos="fade-up-left"
              data-aos-delay="800">
              <p>Get 15 Hours of Free Trial Today!</p>
              <h1>Let's Discuss Your Free Trial</h1>
              <div className="input_all_div">
                <div className="fname_div">
                  <input
                    type="text"
                    name="Name"
                    id="Name"
                    value={values.Name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="First Name"
                  />
                </div>
                <div className="fname_div">
                  <input
                    type="email"
                    name="Email"
                    id="Email"
                    value={values.Email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email"
                  />
                </div>
                <div className="text_area_div">
                  <textarea
                    name="Message"
                    id="Message"
                    cols="30"
                    rows="10"
                    value={values.Message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Message"></textarea>
                </div>
                <button type="submit" className="btn2">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#1249328c",
            }}
            open={true}>
            <span class="loader"></span>
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Index;
