import React from "react";
import "./footer.css";
import Logo from "../../../assets/Logo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebookF,
  faXTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Index = () => {
  return (
    <div className="main_Footer_div">
      <div className="Sub_main_footer">
        <div className="logo_div">
          <div className="main_logo_div">
            <img src={Logo} alt="" />
            <p>Copyright © 2024, All Rights Reserved.</p>
            <div className="icon_div">
              {/* <a
                className="links"
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer">
                <FontAwesomeIcon className="icons" icon={faFacebookF} />
              </a>
              <a
                className="links"
                href="https://www.twitter.com"
                target="_blank"
                rel="noopener noreferrer">
                <FontAwesomeIcon className="icons" icon={faXTwitter} />
              </a>
              <a
                className="links"
                href="https://www.instagram.com"
                target="_blank"
                rel="noopener noreferrer">
                <FontAwesomeIcon className="icons" icon={faInstagram} />
              </a> */}
              <a
                className="links"
                href="https://www.linkedin.com/company/wealthlyte-consultancy-private-limited/"
                target="_blank"
                rel="noopener noreferrer">
                <FontAwesomeIcon className="icons" icon={faLinkedin} />
              </a>
            </div>
          </div>
        </div>
        {/* <div className="logo_div"> */}
        {/* <div className="main_service_div">
            <Link className="service_titel">
              <h4>Services</h4>
            </Link>
            <div className="Service_div">
              <Link className="Service_name" to="/Services">
                <h6>Bookkeeping</h6>
              </Link>
              <Link className="Service_name" to="/Services">
                <h6>Tax Preparation</h6>
              </Link>
              <Link className="Service_name" to="/Services">
                <h6>Finance & Accounting</h6>
              </Link>
              <Link className="Service_name" to="/Services">
                <h6>Payroll Processing</h6>
              </Link>
              <Link className="Service_name" to="/Services">
                <h6>Remote Staffing</h6>
              </Link>
            </div>
          </div> */}
        {/* </div> */}

        <div className="logo_div">
          <div className="main_service_div">
            <Link className="service_titel">
              <h4>Quick Links</h4>
            </Link>
            <div className="Service_div">
              {/* <Link className="Service_name">
                <h6>Help and FAQ</h6>
              </Link> */}
              <Link className="Service_name" to="/Aboutus">
                <h6>About Us</h6>
              </Link>
              <Link className="Service_name" to="/Blog">
                <h6>Blogs</h6>
              </Link>
              <Link className="Service_name" to="/ContactUs">
                <h6>Contact Us</h6>
              </Link>
            </div>
          </div>
        </div>
        <div className="logo_div">
          <div className="main_service_div">
            <Link className="service_titel">
              <h4>Contact Us</h4>
            </Link>
            <div className="Service_div">
              <Link className="Service_name">
                <h6>
                  Addres:- B/1020, Sun Westbank, Opp City Gold, Riverfront,
                  Ashram Road, Ahmedabad, Gujarat, India 380009.
                </h6>
              </Link>
              <Link to="tel: +91 9978 66 1212" className="Service_name">
                <h6>Phone:- IND +91 9978 66 1212</h6>
              </Link>

              <Link to="mailto:wealthlyte@outlook.com" className="Service_name">
                <a href="mailto:wealthlyte@outlook.com">
                  wealthlyte@outlook.com
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
