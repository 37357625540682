import React from "react";
import "./AU_Sec4.css";
import sec4_AU_1 from "../../../assets/Sec4_AU_1.jpg";
import sec4_Au_2 from "../../../assets/Sec4_AU_2.webp";
import Sec4_au_img from "../../../assets/Sec4_au.png";

const Index = () => {
  return (
    <div className="Main_AU_sec4_div">
      <div className="sub_main_div_of_sec4_Au">
        <div className="text_div_of_AU_sec4">
          <h1>Passionately team player workforce</h1>
          <div className="div_text_content_in_sec4">
            <div className="icon_div_of_sec4">
              <span>&#9758;</span>
            </div>
            <div className="div_text_of_sec4_AU">
              <h3>Our Vision</h3>
              <p>
                {" "}
                Our goal is to transform into an organization that offers
                services enhancing the value of our clients’ businesses, all
                while upholding a high standard of integrity. We aim to excel in
                our field and utilize technology to realize our vision.{" "}
              </p>
            </div>
          </div>
          <div className="div_text_content_in_sec4">
            <div className="icon_div_of_sec4">
              <span>&#9758;</span>
            </div>
            <div className="div_text_of_sec4_AU">
              <h3>Our Mission</h3>
              <p>
                {" "}
                Our goal is to empower clients through the provision of precise,
                timely, and cost-efficient outsourced accounting and tax
                services. By fulfilling this mission, we aim to equip our
                clients with the necessary tools to make informed strategic
                business decisions, fostering the growth of their enterprises.{" "}
              </p>
            </div>
          </div>
          <div className="div_text_content_in_sec4">
            <div className="icon_div_of_sec4">
              <span>&#9758;</span>
            </div>
            <div className="div_text_of_sec4_AU">
              <h3>How We Do It?</h3>
              <p>
                {" "}
                We provide top-notch services through a customized approach,
                employing skilled professionals, maintaining proactive
                communication, and prioritizing client satisfaction. Our use of
                cutting-edge technology enhances the efficiency of our
                processes, ensuring that we deliver timely and precise results
                to our clients. Additionally, we place a strong emphasis on
                safeguarding data privacy and security.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="image_div_AU_sec4">
          <div className="img_div_of_sec4_AU">
            <img src={Sec4_au_img} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
