import React from "react";
import "./Acm.css";

const Index = () => {
  return (
    <div className="main_blog_sec1">
      <h1>ADMIN SUPPORT STAFF</h1>

      <p>Services</p>
    </div>
  );
};

export default Index;
