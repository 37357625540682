import React from "react";
import "./Acm2.css";
import img_service1 from "../../../assets/Srvice_page1/BK.png";

const Index = () => {
  const Tick = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85 12.15L6.75 14.25L13.5 21L28.5 6L26.4 3.9L13.5 16.8L8.85 12.15ZM27 15C27 21.6 21.6 27 15 27C8.4 27 3 21.6 3 15C3 8.4 8.4 3 15 3C16.2 3 17.25 3.15 18.3 3.45L20.7001 1.05C18.9 0.45 16.95 0 15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15H27Z"
        fill="#07417bd1"
      />
    </svg>
  );
  return (
    <div className="main_service_sec_2">
      <div className="submain_service_sec2">
        <div className="image_div_for_sec2">
          <div className="_inner_img_div_for_sec2">
            <img src={img_service1} alt="" />
          </div>
        </div>

        <div className="text_contentc_for_sec2">
          <div className="inner_text_div_of_sec2">
            <h2>BOOKKEEPER</h2>
            <p>
              Bookkeeper provides accurate and detailed originated bookkeeping
              services on latest accounting software. Timely punching of
              transactions helps your firm keep track of growth, expenses and
              make sound decisions for budget planning.
            </p>
            <p>
              A bookkeeper is responsible for recording daily financial
              transactions, updating a general ledger and preparing trial
              balances for perusal by accountants. They maintain and file
              requisite documents for taxation compliance. They monitor cash
              flow and produce financial reports to assist managers in taking
              strategic decisions. Bookkeepers may also assist in running
              payroll and generating invoices for your company.
            </p>
            <p>Here are the most common responsibilities of a bookkeeper:</p>
            <ul>
              <li>Establishing different accounts</li>
              <li>
                Maintaining records of financial transactions by posting and
                verifying
              </li>
              <li>Defining bookkeeping policies and procedures</li>
              <li>
                Developing systems to account for financial transactions by
                establishing a chart of accounts
              </li>
              <li>
                Maintaining subsidiary accounts by posting, verifying and
                allocating transactions
              </li>
              <li>Reconciling entries to balance subsidiary accounts</li>
              <li>Maintaining a balanced general ledger</li>
              <li>Preparing a trial balance for the accountants</li>
              <li>
                Preparing financial reports by collecting, analyzing and
                summarizing accounting for information
              </li>
              <li>
                Ensuring compliance with federal, state and local legal
                requirements
              </li>
              <li>Monitoring for variances from the projected budget</li>
              <li>Advising management on compliance needs</li>
              <li>
                Assisting in financial activities such as running payroll and
                generating invoices
              </li>
            </ul>
            <p>
              Additionally, there may be other responsibilities based on the
              unique needs of your business. For example, a bookkeeper may be
              required to learn a specific accounting software system or
              participate in certain meetings.
            </p>
          </div>
        </div>
      </div>
      <div className="sub_bottom_text">
        <div className="last_text">
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Record financial transactions</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>General bookkeeping</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Maintenance of general ledgers</h3>
          </div>

          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Regular reconciliation of account receivables and payables</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Payroll</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Customised solutions for firms</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
