import React from "react";
import "./Acm2.css";
import img_service1 from "../../../assets/Srvice_page1/TaxManager.png";

const Index = () => {
  const Tick = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.85 12.15L6.75 14.25L13.5 21L28.5 6L26.4 3.9L13.5 16.8L8.85 12.15ZM27 15C27 21.6 21.6 27 15 27C8.4 27 3 21.6 3 15C3 8.4 8.4 3 15 3C16.2 3 17.25 3.15 18.3 3.45L20.7001 1.05C18.9 0.45 16.95 0 15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15H27Z"
        fill="#07417bd1"
      />
    </svg>
  );
  return (
    <div className="main_service_sec_2">
      <div className="submain_service_sec2">
        <div className="image_div_for_sec2">
          <div className="_inner_img_div_for_sec2">
            <img src={img_service1} alt="" />
          </div>
        </div>

        <div className="text_contentc_for_sec2">
          <div className="inner_text_div_of_sec2">
            <h2>TAX MANAGER</h2>
            <p>
              The Tax Managers hold bachelor’s or master’s degree in accounting
              or finance. They develop tailor-made tax strategies and policies
              based on firm’s structure and operations. Tax managers will
              oversee the on-time tax filings with accuracy based on state and
              federal laws applicable to firm. Their scope of work will include:
            </p>
            <ul>
              <li>
                Deliver a full range of tax services in compliance with laws and
                regulations within timeframe
              </li>
              <li>
                Build relationships and interact with clients to provide
                excellent planning, consulting and expertise
              </li>
              <li>
                Provide innovative tax planning and review complex income tax
                returns
              </li>
              <li>Identify and mitigate tax risks</li>
              <li>Coordinate accounting staff and assess their performance</li>
              <li> Manage tax provision and tax compliance process</li>
              <li>
                Improve processes by developing or implementing best practices
              </li>
              <li> Manage and coordinate tax audits</li>
              <li>Maintain tax balances on general ledger</li>
              <li>
                Prepare all tax papers in regular basis and handle all
                information data requests
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sub_bottom_text">
        <div className="last_text">
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Strategic tax planning for organisation</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Advisory on implementation of new tax structures</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Manage timely filing of tax returns and reports</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Review and reconciliation of tax returns and projections</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Support services for internal audits</h3>
          </div>
          <div className="text_of_hedlines">
            <span>{Tick}</span>
            <h3>Preparation of responses for IRS Audits</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
