import React, { useState, useEffect } from "react";
import "./Blog_2.css";
import blog2img from "../../../assets/Blog_sec2.png";
import { format, subYears } from "date-fns";
import BlogData from "./BlogData";
import { useNavigate, useLocation } from "react-router-dom";

const getRandomDate = (startDate, endDate) => {
  const diff = endDate.getTime() - startDate.getTime();
  const newDiff = diff * Math.random();
  const newDate = new Date(startDate.getTime() + newDiff);
  return newDate;
};

const generateRandomDates = (numDates, startDate, endDate) => {
  const randomDates = [];
  for (let i = 0; i < numDates; i++) {
    randomDates.push(getRandomDate(startDate, endDate));
  }
  return randomDates;
};

const Index = () => {
  const navigate = useNavigate();
  const loaction = useLocation();
  const [dates, setDates] = useState([]);
  console.log("🚀 ~ Index ~ dates:", dates);

  const handleGenerateDates = () => {
    const endDate = new Date();
    const startDate = subYears(endDate, 5);
    const randomDates = generateRandomDates(5, startDate, endDate);
    setDates(randomDates);
  };

  useEffect(() => {
    handleGenerateDates();
  }, []);
  function truncateDescription(description) {
    return description.split(" ").slice(0, 14).join(" ") + "...";
  }

  return (
    <div className="main_blog_sec2_div">
      <div className="sub_main_blog_sec2_div">
        <div className="blog_box_of_sec2">
          {BlogData.map((item, index) => {
            return (
              <div className="blog_no1_box" key={index}>
                <div className="main_img_div_of_blogsec2">
                  <div className="image_box_of_blog_div_sec2">
                    <img src={item?.img} alt="" />
                  </div>
                  <button className="blog_btn">{item?.title}</button>
                </div>
                <div className="text_of_blog_no1">
                  <h1>{item.title}</h1>
                  <h3>
                    Wealthlyte -{" "}
                    {dates.length > 0 && dates[0]
                      ? format(dates[index], "yyyy-MM-dd")
                      : "Loading..."}
                  </h3>

                  <p>{truncateDescription(item?.description)}</p>

                  <button
                    className="btn2"
                    onClick={() =>
                      navigate("/Blogdetails", {
                        state: item,
                      })
                    }>
                    Read More
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="blog_info_box">
          <div className="reent_blog_main_div">
            <h3>recent posts</h3>
            {BlogData.slice(0, 2).map((item, index) => {
              return (
                <div className="blog_text_div_of_sec2">
                  <div className="recent_blog_sec2_img">
                    <div className="recent_img_blog_box">
                      <img src={item.img} alt="" />
                    </div>
                  </div>
                  <div className="text_of_recent_blog_div">
                    <h4> {item.title} </h4>
                    <p>
                      {dates.length > 0 && dates[0]
                        ? format(dates[index], "yyyy-MM-dd")
                        : "Loading..."}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="tag_blog_main_div">
            <h2>Tag's</h2>
            <span className="btn_tag">tax preparation outsourcing</span>
            <span className="btn_tag">tax returns services</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
