import React from "react";
import "./S_sec1.css";

const index = () => {
  return (
    <div>
      <div className="main_Service_sec1">
        <h1> Services</h1>
      </div>
    </div>
  );
};

export default index;
