import * as Yup from "yup";

export const ContactusSchemas = Yup.object().shape({
  Name: Yup.string().required("Please Enter Your Full Name"),
  Email: Yup.string()
    .email("Invalid email address, please add @ or .com")
    .required("Please Enter Your Email"),
  Subject: Yup.string().required("Please Enter Your Subject"),
  Message: Yup.string().required("Please Enter Your Message"),
});
