import React, { useRef, useState } from "react";
import "./S_sec2.css";
import image1 from "../../../assets/Service_section2/S_sec2_1.png";
import image2 from "../../../assets/Service_section2/S_sec2_2.png";
import image3 from "../../../assets/Service_section2/S_sec2_3.png";
import image4 from "../../../assets/Service_section2/S_sec2_4.png";
import image5 from "../../../assets/Service_section2/S_sec2_5.png";
import image6 from "../../../assets/Service_section2/S_sec2_6.png";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards } from "swiper/modules";
import { useNavigate, useLocation } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();

  const S_sec2Database = [
    {
      id: 1,
      title: "ACCOUNTING MANAGER",
      // description:
      //   "Are you tired of spending your free time reconciling your books, but don’t want to spend a small fortune hiring an expensive full-time CFO or controller?",
      // img: image1,
    },
    {
      id: 1,
      title: "Bookkeeping",
      description:
        "Are you tired of spending your free time reconciling your books, but don’t want to spend a small fortune hiring an expensive full-time CFO or controller?",
      img: image1,
    },
    {
      id: 2,
      title: "Tax Preparation",
      description:
        "Wealthlyte is powered by professionals capable of preparing the simplest to the most complex returns.",
      img: image2,
    },
    {
      id: 3,
      title: "Finance & Accounting",
      description:
        "Are you running your business at peak performance? Call on us to increase efficiency, stay in compliance, and boost revenue and profits.",
      img: image3,
    },
    {
      id: 4,
      title: "Payroll Processing",
      description:
        "Wealthlyte payroll outsourcing solutions are flexible and can be scaled and adapted to fit business needs, for all sizes of business.",
      img: image4,
    },
    {
      id: 5,
      title: "Advisory Services",
      description:
        "Advisory management services allow private individuals to consult with investment professionals before making changes to their portfolios.",
      img: image5,
    },
    {
      id: 6,
      title: "Remote Staffing",
      description:
        "Remote accounting staff provide numerous advantages to CPAs and accounting firms, including improved efficiency and cost savings.",
      img: image6,
    },
    {
      id: 6,
      title: "Remote Staffing",
      description:
        "Remote accounting staff provide numerous advantages to CPAs and accounting firms, including improved efficiency and cost savings.",
      img: image6,
    },
  ];
  const service_name_data = [
    {
      id: 1,
      title: "ACCOUNTING MANAGER",
      link: "/AccountingManager",
    },
    {
      id: 2,
      title: " STAFF ACCOUNTANT",
      link: "/StaffAccountant",
    },
    {
      id: 3,
      title: "BOOKKEEPER",
      link: "/Bookkeeper",
    },
    {
      id: 4,
      title: "TAX PREPARER",
      link: "/TaxPreparer",
    },
    {
      id: 5,
      title: "TAX MANAGER",
      link: "/TaxManager",
    },
    // {
    //   id: 6,
    //   title: "TAX MANAGER",
    // },
    {
      id: 7,
      title: "AUDIT ASSOCIATES",
      link: "/AuditAssociates",
    },
    {
      id: 8,
      title: "DATA ENTRY SPECIALIST",
      link: "/DataEntrySpecialist",
    },
    {
      id: 9,
      title: "BILLING EXECUTIVE",
      link: "/Billingexecutive",
    },
    {
      id: 10,
      title: "AR/AP ASSISTANT",
      link: "/ArApAssistant",
    },
    // {
    //   id: 11,
    //   title: "ADMIN SUPPORT STAFF",
    //   link: "/AdminSupportStaff",
    // },
    // {
    //   id: 12,
    //   title: "CLIENT SERVICE EXECUTIVE",
    //   link: "/ClientServiceExecutive",
    // },
    // {
    //   id: 13,
    //   title: " BUSINESS DEVELOPMENT ASSOCIATE",
    //   link: "/BusinessDevelopmentAssociate",
    // },
    // {
    //   id: 14,
    //   title: "VIRTUAL/EXECUTIVE ASSISTANT",
    //   link: "/VirtualExecutiveAssistant",
    // },
  ];
  // &rArr;
  // const handleclick = (link) => {
  //   navigate(link);
  // };
  return (
    <div className="main_S_sec2_div">
      <div className="sub_main_Service_sec2">
        <div className="service_heading_sec2">
          <h1>Outsource Accounting Services</h1>
          <p>
            Outsource accounting services offers a notable advantage by allowing
            businesses to optimize their processes and adopt automation.
          </p>
        </div>
        {/* <div className="service_card_div">
          {S_sec2Database?.map((item,Iindex) => {
            return (
              <div className="srevices_card" key={item.id}>
                <div className="services_images_div">
                  <div className="s_img_div">
                    <img src={item.img} alt="" />
                  </div>
                </div>

                <div className="services_text_div">
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                  <button type="button" className="btn1">
                    Leran more
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="service_card_div_of_mobile">
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="Servicepage_section2_main">
            {S_sec2Database?.map((item, index) => {
              return (
                <SwiperSlide className="main_section2_swiper">
                  <div className="srevices_card" key={item.id}>
                    <div className="services_images_div">
                      <div className="s_img_div">
                        <img src={item.img} alt="" />
                      </div>
                    </div>

                    <div className="services_text_div">
                      <h2>{item.title}</h2>
                      <p>{item.description}</p>
                      <button type="button" className="btn1">
                        Leran more
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div> */}
        <h2 className="service_heading_text">All Services</h2>
        <div className="main_service_list_div">
          {service_name_data.map((item, index) => {
            return (
              <div
                className="service_name_div"
                key={index}
                onClick={() => navigate(item.link)}>
                <p>{item?.title}</p> <span>&rArr; </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Index;
