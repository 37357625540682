import React from "react";
import "./Blog_main.css";
import Blog1 from "./Blog_1/index";
import Blog2 from "./Blog_2/index";

const Index = () => {
  return (
    <div>
      <Blog1 />
      <Blog2 />
    </div>
  );
};

export default Index;
