import React from "react";
import "./AU_Sec5.css";
import Au_sec5_img from "../../../assets/Sec5_AU.png";

const Index = () => {
  const tick = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <path
        d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z"
        fill="#1E4EC4"></path>
    </svg>
  );
  const SecAuData = [
    { id: 1, icon: tick, headingtext: "Limitation on documents printing." },
    {
      id: 2,
      icon: tick,
      headingtext: "Internet browsing regulated by a firewall",
    },
    {
      id: 3,
      icon: tick,
      headingtext: "Prohibition of external plug-in device usage",
    },
    {
      id: 4,
      icon: tick,
      headingtext: "Consistent implementation of security updates.",
    },
    {
      id: 5,
      icon: tick,
      headingtext: "Access to potentially harmful websites is restricted.",
    },
    {
      id: 6,
      icon: tick,
      headingtext: "VPN safeguarding while operating on client servers.",
    },
    {
      id: 7,
      icon: tick,
      headingtext: "Strict adherence to on-site work; no WH option available.",
    },
    {
      id: 8,
      icon: tick,
      headingtext:
        "Desktop computers and other technological devices equipped with the latest configurations.",
    },
  ];

  return (
    <div className="main_Au_div_sec5">
      <div className="sub_main_of_AU_sec5">
        <div className="img_div_Sec5_main">
          <div className="image_box_of_sec5_au">
            <img src={Au_sec5_img} alt="" />
          </div>
        </div>
        <div className="text_div_of_AU_sec5_main">
          <h1>IT infrastructure & Security measures</h1>
          <div className="map_div_text_of_sec5_Au">
            {SecAuData.map((item, index) => {
              return (
                <div className="div_text_of_sec5_AU" key={index}>
                  <span>{item.icon}</span>
                  <h4>{item.headingtext}</h4>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
