import React from "react";
import Navbar from "../../components/UI/Navbar/index";
import Sec1 from "./section1/index";
import Sec2 from "./section2/index";

const Index = () => {
  return (
    <div>
      <Sec1 />
      <Sec2 />
    </div>
  );
};

export default Index;
