import React from "react";
import "./con_1.css";

const Index = () => {
  return (
    <div className="main_blog_sec1">
      <h1> Contact Us </h1>
    </div>
  );
};

export default Index;
