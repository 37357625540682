import React from "react";
import "./AUsec1.css";

const Index = () => {
  return (
    <div className="AU_sec1_main_div">
      <h1>About US</h1>
    </div>
  );
};

export default Index;
