import { useEffect } from "react";
import "./Sec4.css";
import sec4img from "../.../../../../../assets/Sec4.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGraduate,
  faChartLine,
  faScissors,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";
const Index = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="Sec4_main_div">
      <div className="Sec4_sub_div">
        <div className="sec4_main_img_div">
          <div
            className="sec4_images_div"
            data-aos="fade-up-right"
            data-aos-delay="800">
            <img src={sec4img} alt="" />
          </div>
        </div>
        <div className="sec4_main_text_div">
          <h1 data-aos="fade-up-left">Revitalize Your CPA Firm</h1>
          <h5 data-aos="fade-up-left">Ignite Growth and Achieve New Heights</h5>

          <div className="sec4_inner_text_div" data-aos="fade-up-left">
            <span>
              <FontAwesomeIcon icon={faUserGraduate} />
            </span>
            <div className="sec4_text_content_div">
              <h3> Talent Shortage </h3>
              <p>
                Hiring and retention of skilled accounting professionals to
                scale workforce quickly and sustainably is a huge problem for
                CPA firm owners.
              </p>
            </div>
          </div>
          <div className="sec4_inner_text_div" data-aos="fade-up-left">
            <span>
              <FontAwesomeIcon icon={faChartLine} />
            </span>
            <div className="sec4_text_content_div">
              <h3> High Overheads </h3>
              <p>
                Low margin compliance work drives up infrastructure and other
                associated costs, leaving little time for lucrative strategic
                accounting work.
              </p>
            </div>
          </div>
          <div className="sec4_inner_text_div" data-aos="fade-up-left">
            <span>
              <FontAwesomeIcon icon={faScissors} />
            </span>
            <div className="sec4_text_content_div">
              <h3> Undercharging Clients </h3>
              <p>
                Clients demand work to be turned around faster, expect more
                support and proactive accountants and pay lower fees for these
                burgeoning demands.
              </p>
            </div>
          </div>
          <div className="sec4_inner_text_div" data-aos="fade-up-left">
            <span>
              <FontAwesomeIcon icon={faCertificate} />
            </span>
            <div className="sec4_text_content_div">
              <h3> Quality Issues </h3>
              <p>
                Lack of quality control, missing deadlines, errors and omissions
                are prevalent in the accounting profession, resulting in client
                dissatisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
