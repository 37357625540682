import { useEffect } from "react";
import "./Sec2.css";
import Sec2img from "../../../../assets/Sec2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AOS from "aos";
import "aos/dist/aos.css";
const Index = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="Sec2_main_div">
      <div className="Sec2_sub_div">
        <div className="imgage_sec2">
          <div className="image_div" data-aos="fade-right" data-aos-delay="600">
            <img src={Sec2img} alt="" />
          </div>
        </div>
        <div className="sec2_content_div">
          <div className="text_of_heading">
            <h1 data-aos="fade-left">Strategic and Proactive Accounting</h1>
            <p data-aos="fade-left">
              We offer comprehensive strategic accounting solutions tailored for
              intricate organizations. Our collaboration is with
              forward-thinking business leaders who appreciate the significance
              of real-time financial and operational data in making informed
              decisions. We view accounting not merely as an expense but as a
              valuable investment, boasting a compelling return on investment
              (ROI). Far beyond conventional bookkeeping, we embody proactive
              accountants committed to fostering CPAs firm’s growth, enabling
              impactful results, and cultivating a forward-thinking team.
              <br />
              <span>
                Some of the Outsourcing Accounting Strategies for CPA firms:
              </span>
            </p>
            <div className="second_text_of_sec2">
              <p data-aos="fade-left">
                <span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
                Reduced Overhead Costs
              </p>
              <p data-aos="fade-left">
                <span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
                Access to Expertise and Specialized Skills
              </p>
              <p data-aos="fade-left">
                <span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
                Improve Data Security and Confidentiality
              </p>
              <p data-aos="fade-left">
                <span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
                Eliminate Staffing Concerns
              </p>
              <p data-aos="fade-left">
                <span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
                Time Savings and Efficiency
              </p>
              <p data-aos="fade-left">
                <span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
                Access to Advanced Technology and Tools
              </p>
            </div>
            <button className="btn2" data-aos="fade-left">
              Discover More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
