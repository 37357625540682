import React from "react";
import "./About.css";
import AU_Sec1 from "./AU_Sec1/Index";
import AU_Sec2 from "./AU_Sec2/index";
import AU_Sec3 from "./AU_Sec3/index";
import AU_Sec4 from "./AU_Sec4/index";
import AU_Sec5 from "./AU_Sec5/index";

const Index = () => {
  return (
    <div>
      <AU_Sec1 />
      <AU_Sec2 />

      <AU_Sec3 />
      <AU_Sec4 />
      <AU_Sec5 />
    </div>
  );
};

export default Index;
