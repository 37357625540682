import React from "react";
import "./Contact.css";
import Contact_1 from "./con_1/index";
import Contact_2 from "./con_2/index";
import Contact_3 from "./Con_3/index";

const Index = () => {
  return (
    <div>
      <Contact_1 />
      <Contact_2 />
      <Contact_3 />
    </div>
  );
};

export default Index;
